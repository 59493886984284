export const environment = {
	/**
	 * Configuration
	 */

	apiUrl: 'https://api.dev.syniva.es',
	entrypoint: 'https://api.dev.syniva.es/api',
	entrypointAuth: 'https://api.dev.syniva.es/authentication_token',

	/**
	 * Enums
	 */

	/* Order */

	// Order Types
	orderTypes: {
		INTERNAL: 'interno',
		EXTERNAL: 'externo',
	},

	// Order status
	orderStatus: {
		REQUESTED: 'solicitado',
		PROCESSED: 'procesado',
		CANCELED: 'cancelado',
		RETURNED: 'devolucion',
		SUPPLEMENT: 'suplemento',
		ADMITED: 'admitido',
		BREAKAGE: 'rotura',
		PREPARED: 'preparado',
		DISPATCHED: 'expedido',
		SHIPPED: 'enviado',
		DELIVERED: 'entregado',
	},

	/* Payment */

	// Payment status
	paymentStatus: {
		PROCESING: 'procesando',
		CONFIRMED: 'confirmado',
		INCIDENCE: 'incidencia'
	},

	/* Shipment */

	// Shipment type
	shipmentType: {
		DIRECT: 'directo',
		CONSOLIDATED: 'consolidado',
	}
};
