import {
    Create,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';

const VariableCreate = (props) => (
    <Create {...props} title='Metodos de envio'>
        <SimpleForm redirect='list'>
            <TextInput source='name' label='Nombre' validate={required()} />
            <TextInput source='value' label='Valor' validate={required()} />
            <TextInput source='description' label='Descripcion' validate={required()} />
        </SimpleForm>
    </Create>
);

export default VariableCreate;
