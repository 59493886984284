import {
	List,
	Datagrid,
	TextField,
	TextInput,
	EditButton,
	NumberInput,
	NumberField,
	ReferenceField,
	ChipField
} from 'react-admin';

const filters = [
	<TextInput label='Nombre' source='name' />,
	<NumberInput label='Orden' source='weigth' />,
	<TextInput label='Padre' source='parent.name' />
];

const CmsCategoryList = (props) => (
	<List {...props} filters={filters}>
		<Datagrid>
			<TextField source='name' label='Nombre' />
			<NumberField source='weigth' label='Orden' />
			<ReferenceField reference='cms_categories' source='parentIri' label='Padre'>
				<ChipField source='name' />
			</ReferenceField>

			<EditButton />
		</Datagrid>
	</List>
);

export default CmsCategoryList;
