import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import ShipmentList from './ShipmentList/ShipmentList';

const ShipmentResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={ShipmentList}
			create={null}
			edit={null}
			show={null}
		/>
	);
};

ShipmentResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default ShipmentResource;
