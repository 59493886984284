import {
	List,
	Datagrid,
	TextField,
	DateField,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	ReferenceField,
	TextInput,
	BooleanInput,
	DateInput,
	NumberInput,
	NumberField,
	BooleanField,
	EditButton,
	ArrayField,
	ImageField,
} from 'react-admin';
import CustomChipField from '../../../Field/CustomChipField/CustomChipField.tsx';
import CustomUrlField from '../../../Field/CustomUrlField/CustomUrlField.tsx';

const postFilters = [
	<TextInput label='Nombre' source='name' />,
	<TextInput label='Descripción' source='description' />,
	<TextInput label='Estado' source='status' />,
	<BooleanInput label='Está en Home' source='isInHome' />,
	<DateInput
		label='Fecha de activación anterior a'
		source='activationDate[before]'
	/>,
	<DateInput
		label='Fecha de activación posterior a'
		source='activationDate[after]'
	/>,
	<NumberInput label='IGIC' source='igic' />,
	<NumberInput label='Precio de envío gratis' source='freeShipmentPrice' />,
	<NumberInput label='precio de envío' source='shipmentPrice' />,
	<TextInput label='Categoría' source='categories.name' />,
	<TextInput label='Logo' source='logo.name' />,
	<TextInput label='Etiqueta' source='tags' />,
];

const MarketplaceList = (props) => (
	<List {...props} filters={postFilters}>
		<Datagrid>
			<TextField source='name' label='Nombre' />
			<TextField source='description' label='Descripción' />
			<TextField source='status' label='Estado' />
			<CustomUrlField source='url' label='Url' />
			<BooleanField source='isInHome' label='Está en Home' />
			<DateField source='activationDate' label='Fecha de activación' />
			<NumberField
				source='igic'
				label='IGIC'
			/>
			<NumberField
				source='freeShipmentPrice'
				label='Precio de envío gratis'
				locales='es-ES'
				options={{ style: 'currency', currency: 'EUR' }}
			/>

			<NumberField
				source='shipmentPrice'
				label='Precio de envío'
				locales='es-ES'
				options={{ style: 'currency', currency: 'EUR' }}
			/>

			<ReferenceArrayField
				source='categories'
				reference='market_place_categories'
				label='Categorías'
			>
				<SingleFieldList>
					<ChipField source='name' />
				</SingleFieldList>
			</ReferenceArrayField>

			<ReferenceField label='Logo' source='logo.@id' reference='media_objects'>
				<ChipField source='name' />
			</ReferenceField>
			<ArrayField source='tags' label='Etiquetas'>
				<SingleFieldList linkType={false}>
					<CustomChipField />
				</SingleFieldList>
			</ArrayField>

			<EditButton label='Editar' />
		</Datagrid>
	</List>
);

export default MarketplaceList;
