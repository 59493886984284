import * as React from 'react';
import { memo, FC } from 'react';
import get from 'lodash/get';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useRecordContext } from 'ra-core';

import { PublicFieldProps, InjectedFieldProps } from 'react-admin';

const useStyles = makeStyles(
	{
		chip: { margin: 4, cursor: 'inherit' },
	},
	{ name: 'RaChipField' }
);

export const CustomChipField: FC<ChipFieldProps> = memo((props) => {
	const { className, classes: classesOverride, source } = props;
	const record = useRecordContext(props);
	const classes = useStyles(props);

	return (
		<Chip
			className={classnames(classes.chip, className)}
			label={
				source !== null && source !== undefined ? get(record, source) : record
			}
		/>
	);
});

CustomChipField.defaultProps = {
	addLabel: true,
};

CustomChipField.propTypes = {
	// @ts-ignore
	...CustomChipField.propTypes,
};

CustomChipField.displayName = 'ChipField';

export interface ChipFieldProps
	extends PublicFieldProps,
		InjectedFieldProps,
		Omit<ChipProps, 'label'> {}

export default CustomChipField;
