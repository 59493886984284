import {
  List,
  Datagrid,
  TextField,
  NumberField,
  TextInput,
  NumberInput,
  EditButton,
  ArrayField,
  SingleFieldList,
} from "react-admin";
import CustomChipField from "../../../Field/CustomChipField/CustomChipField.tsx";

const filters = [
  <TextInput source="name" label="Nombre" />,
  <TextInput source="cpPrefix" label="Prefijo CP" />,
  <NumberInput source="planeExtraCost" label="Coste extra avión" />,
  <NumberInput source="boatExtraCost" label="Coste extra barco" />,
];

const IslandList = (props) => (
  <List {...props} filters={filters}>
    <Datagrid>
      <TextField source="name" label="Nombre" />
      <ArrayField source="cpPrefixes" label="Prefijos">
        <SingleFieldList linkType={false}>
          <CustomChipField />
        </SingleFieldList>
      </ArrayField>
      <TextField source="province" label="Provincia" />

      <NumberField
        source="planeExtraCost"
        label="Coste extra avión"
        locales="es-ES"
        options={{ style: "currency", currency: "EUR" }}
      />
      <NumberField
        source="boatExtraCost"
        label="Coste extra barco"
        locales="es-ES"
        options={{ style: "currency", currency: "EUR" }}
      />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
);

export default IslandList;

// Hola
