import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";

const IslandCreate = (props) => (
  <Create {...props} title="Islas">
    <SimpleForm redirect="list">
      <TextInput source="name" label="Nombre" validate={required()} />
      <ArrayInput source="cpPrefixes" label="Prefijos">
        <SimpleFormIterator>
          <TextInput label="Prefijo" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="province" label="Provincia" validate={required()} />

      <NumberInput
        source="planeExtraCost"
        label="Coste extra avión"
        min={0}
        validate={required()}
      />
      <NumberInput
        source="boatExtraCost"
        label="Coste extra barco"
        min={0}
        validate={required()}
      />
    </SimpleForm>
  </Create>
);

export default IslandCreate;
