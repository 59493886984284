import React, { useState, useCallback } from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import MediaObjectQuickCreateButton from '../../Button/MediaObjectQuickCreateButton/MediaObjectQuickCreateButton';
const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
});

const MediaObjectQuickCreateInput = (props) => {
	const classes = useStyles();
	const [version, setVersion] = useState(0);
	const handleChange = useCallback(() => setVersion(version + 1), [version]);
	return (
		<div className={classes.root}>
			<ReferenceInput key={version} {...props} filter={{ fileType: props.fileType }}>
				<SelectInput optionText='name' />
			</ReferenceInput>
			<MediaObjectQuickCreateButton size={props.size} onChange={handleChange} />
		</div>
	);
};

export default MediaObjectQuickCreateInput;
