import {
	List,
	Datagrid,
	ReferenceField,
	ChipField,
	BooleanField,
	BooleanInput,
	EditButton,
} from 'react-admin';
import CustomUrlField from '../../../Field/CustomUrlField/CustomUrlField.tsx';
import { environment } from '../../../../environments/environment';
import { TextField } from '@mui/material';

const filters = [
	<TextField label='Imagen' source='image.name' />,
	<BooleanInput label='Está en Home' source='isInHome' />,
];

const BannerList = (props) => (
	<List {...props} filters={filters}>
		<Datagrid>
			<ReferenceField
				label='Imagen'
				source='image.@id'
				reference='media_objects'
			>
				<ChipField source='name' />
			</ReferenceField>
			<CustomUrlField label='Url' prefix={environment.apiUrl} source='image.contentUrl' />
			<BooleanField label='Está en Home' source='isInHome' />
			<EditButton label='Editar' />
		</Datagrid>
	</List>
);

export default BannerList;
