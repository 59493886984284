import { AppBar } from 'react-admin';
import logo from '../../../logo.png';
import Typography from '@mui/material/Typography';

const CustomAppBar = (props) => (
	<AppBar {...props} className='no-printme' style={{ backgroundColor: '#c0f140', color: '#424949', zIndex: 99 }}>
		<Typography
			variant='h6'
			color='inherit'
			id='react-admin-title'
			style={{ width: '200px' }}
		/>
		<div style={{ width: '100%', textAlign: 'center' }}>
			<img src={logo} style={{ height: '50px' }} alt='Logo' />
		</div>
		<span />
	</AppBar>
);

export default CustomAppBar;
