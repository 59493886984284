import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import BannerList from './BannerList/BannerList';
import BannerCreate from './BannerCreate/BannerCreate';
import BannerEdit from './BannerEdit/BannerEdit';

const BannerResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={BannerList}
			create={BannerCreate}
			edit={BannerEdit}
			show={null}
		/>
	);
};

BannerResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default BannerResource;
