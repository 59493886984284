import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	ChipField,
	TextInput,
} from 'react-admin';

const filters = [
	<TextInput label='Número' source='number' />,
	<TextInput label='Usuario' source='user.email' />,
];

const CardList = (props) => (
	<List {...props} filters={filters}>
		<Datagrid>
			<TextField source='number' label='Número' />

			<ReferenceField label='Usuario' source='user' reference='users'>
				<ChipField source='email' />
			</ReferenceField>
		</Datagrid>
	</List>
);

export default CardList;
