import {
	Create,
	SimpleForm,
	TextInput,
	DateInput,
	ReferenceArrayInput,
	SelectArrayInput,
	BooleanInput,
	NumberInput,
	required,
	ArrayInput,
	SimpleFormIterator
} from 'react-admin';
import MediaObjectQuickCreateInput from '../../../Input/MediaObjectQuickCreateInput/MediaObjectQuickCreateInput';

const MarketplaceCreate = (props) => (
	<Create {...props} title='Tiendas'>
		<SimpleForm redirect='list'>
			<TextInput source='name' label='Nombre' validate={required()} />
			<TextInput
				source='description'
				label='Descripción'
				validate={required()}
			/>
			<TextInput source='status' label='Estado' validate={required()} />
			<TextInput source='url' label='Url' validate={required()} />
			<DateInput
				source='activationDate'
				label='Fecha de activación'
				validate={required()}
			/>

			<ReferenceArrayInput
				source='categories'
				reference='market_place_categories'
				label='Categorías'
			>
				<SelectArrayInput optionText='name' />
			</ReferenceArrayInput>

			<MediaObjectQuickCreateInput
				source='logo'
				reference='media_objects'
				label='Logo'
			/>

			<NumberInput
				source='igic'
				label='IGIC'
				min={0}
				validate={required()}
			/>

			<NumberInput
				source='freeShipmentPrice'
				label='Precio de envío gratis'
				min={0}
				validate={required()}
			/>
			<NumberInput
				source='shipmentPrice'
				label='Precio de envío'
				min={0}
				validate={required()}
			/>

			<BooleanInput
				source='isInHome'
				label='Está en Home'
				defaultValue={false}
			/>
			<ArrayInput source='tags' label="Etiquetas">
				<SimpleFormIterator>
					<TextInput label="Etiqueta" />
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	</Create>
);

export default MarketplaceCreate;
