import React, { useState } from "react";
import {
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  required,
  NumberInput
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MediaObjectQuickCreateInput from '../../Input/MediaObjectQuickCreateInput/MediaObjectQuickCreateInput';

function MarketOrderQuickEditButton({ onChange, order }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("market_orders");
  const notify = useNotify();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    let payload = {
      purchaseId: values.purchaseId,
      transactionId: values.transactionId,
      purchaseAmount: values.purchaseAmount,
      trackingId: values.trackingId,
      invoiceNumber: values.invoiceNumber,
      invoice: values.invoice,
      order: order
    }

    console.log(payload)

    create(
      { payload: { data: payload } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Crear">
        <IconContentAdd />
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Realizar compra"
      >
        <DialogTitle>Realizar compra</DialogTitle>

        <FormWithRedirect
          resource="market_orders"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput
                  source="purchaseId"
                  label="ID pedido marca"
                  validate={required()}
                />
                <br />
                <TextInput
                  source="transactionId"
                  label="ID operación bancaria"
                  validate={required()}
                />
                <br />
                <NumberInput
                  source="purchaseAmount"
                  label="Importe compra"
                  validate={required()}
                />
                <br />
                <TextInput source="trackingId" label="Nº seguimiento envío" />
                <br />

                <TextInput source="invoiceNumber" label="Nº factura" />
                <br />
                <MediaObjectQuickCreateInput
                  source='invoice'
                  label='Factura'
                  reference='media_objects'
                  fileType='application/pdf'
                  size={false}
                />
                <br />
              </DialogContent>

              <DialogActions>
                <Button
                  label="Cancelar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>

                <SaveButton
                  label="Guardar"
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default MarketOrderQuickEditButton;
