import {
	Edit,
	SimpleForm,
	TextInput,
	DateInput,
	ReferenceArrayInput,
	SelectArrayInput,
	BooleanInput,
	NumberInput,
	ArrayInput,
	SimpleFormIterator
} from 'react-admin';
import MediaObjectQuickCreateInput from '../../../Input/MediaObjectQuickCreateInput/MediaObjectQuickCreateInput';

const MarketplaceEdit = (props) => {
	const transform = (data) => ({
		...data,
		logo: data.logo['@id']
	});

	return (
		<Edit {...props} transform={transform} title='Tiendas'>
			<SimpleForm redirect='list'>
				<TextInput source='name' label='Nombre' />
				<TextInput source='description' label='Descripción' />
				<TextInput source='status' label='Estado' />
				<TextInput source='url' label='Url' />
				<DateInput source='activationDate' label='Fecha de activación' />

				<ReferenceArrayInput
					source='categories'
					reference='market_place_categories'
					label='Categorías'
				>
					<SelectArrayInput optionText='name' />
				</ReferenceArrayInput>

				<MediaObjectQuickCreateInput
					source='logo.@id'
					reference='media_objects'
					label='Logo'
				/>

				<NumberInput
					source='igic'
					label='IGIC'
					min={0}
				/>

				<NumberInput
					source='freeShipmentPrice'
					label='Precio de envío gratis'
					min={0}
				/>

				<NumberInput
					source='shipmentPrice'
					label='Precio de envío'
					min={0}
				/>

				<BooleanInput
					source='isInHome'
					label='Está en Home'
					defaultValue={false}
				/>

				<ArrayInput source='tags' label="Etiquetas">
					<SimpleFormIterator>
						<TextInput label="Etiqueta" />
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Edit>
	);
};

export default MarketplaceEdit;
