import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import ShipmentMethodList from './ShipmentMethodList/ShipmentMethodList';
import ShipmentMethodCreate from './ShipmentMethodCreate/ShipmentMethodCreate';
import ShipmentMethodEdit from './ShipmentMethodEdit/ShipmentMethodEdit';

const ShipmentMethodResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={ShipmentMethodList}
			create={ShipmentMethodCreate}
			edit={ShipmentMethodEdit}
			show={null}
		/>
	);
};

ShipmentMethodResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default ShipmentMethodResource;
