import { Create, SimpleForm, BooleanInput, required } from 'react-admin';
import QuickCreateReferenceInput from '../../../Input/MediaObjectQuickCreateInput/MediaObjectQuickCreateInput';

const BannerCreate = (props) => (
	<Create {...props}>
		<SimpleForm redirect='list' title='Banners'>
			<QuickCreateReferenceInput
				source='image'
				label='Imagen'
				reference='media_objects'
				validate={required()}
			/>
			<BooleanInput
				source='isInHome'
				label='Está en Home'
				defaultValue={false}
			/>
		</SimpleForm>
	</Create>
);

export default BannerCreate;
