import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
	fetchHydra as baseFetchHydra,
	hydraDataProvider as baseHydraDataProvider,
	useIntrospection,
} from '@api-platform/admin';
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';
import { environment } from '../environments/environment';

const getHeaders = () =>
	localStorage.getItem('auth')
		? {
			Authorization: `Bearer ${localStorage.getItem('auth')}`,
		}
		: {};

const fetchHydra = (url, options = {}) =>
	baseFetchHydra(url, {
		...options,
		headers: getHeaders,
	});

const RedirectToLogin = () => {
	const introspect = useIntrospection();

	if (localStorage.getItem('auth')) {
		introspect();
		return <></>;
	}
	return <Redirect to='/login' />;
};

const apiDocumentationParser = async () => {
	try {
		return await parseHydraDocumentation(environment.entrypoint, { headers: getHeaders });
	} catch (result) {
		const { api, response, status } = result;
		if (status !== 401 || !response) {
			throw result;
		}

		// Prevent infinite loop if the token is expired
		localStorage.removeItem('auth');

		return {
			api,
			response,
			status,
			customRoutes: [<Route key='/' path='/' component={RedirectToLogin} />],
		};
	}
};

const DataProvider = {
	...baseHydraDataProvider({
		entrypoint: environment.entrypoint,
		httpClient: fetchHydra,
		apiDocumentationParser,
		useEmbedded: true,
	}),

	register: (params) => {
		return fetch(`${environment.entrypoint}/register`, {
			method: 'POST',
			body: JSON.stringify(params),
			headers: new Headers({
				'content-type': 'application/json',
			}),
		}).then((response) => {
			return { ...response, data: params };
		});
	},
};

export default DataProvider;
