import {
	List,
	Datagrid,
	TextField,
	TextInput,
	NumberInput,
	EditButton,
} from 'react-admin';
import { environment } from '../../../../environments/environment';
import CustomUrlField from '../../../Field/CustomUrlField/CustomUrlField.tsx';

const filters = [
	<TextInput source='fileType' label='Tipo de archivo' />,
	<TextInput source='description' label='Descripción' />,
	<TextInput source='title' label='Título' />,
	<TextInput source='name' label='Nombre' />,
	<NumberInput source='size' label='Tamaño' />,
];

const MediaObjectList = (props) => (
	<List {...props} filters={filters}>
		<Datagrid>
			<TextField source='fileType' label='Tipo de archivo' />
			<TextField source='description' label='Descripción' />
			<TextField source='title' label='Título' />
			<TextField source='name' label='Nombre' />
			<TextField source='size' label='Tamaño' />
			<CustomUrlField prefix={environment.apiUrl} source='contentUrl' label='Url' />
			<EditButton label='Editar' />
		</Datagrid>
	</List>
);

export default MediaObjectList;
