import {
	TextInput,
	Create,
	SimpleForm,
	required,
	NumberInput,
	ReferenceInput,
	SelectInput
} from 'react-admin';

const CmsCategoryCreate = (props) => {
	const transform = (data) => ({
		...data,
		childrens: [],
	});

	return (
		<Create {...props} transform={transform}>
			<SimpleForm redirect='list'>
				<TextInput source='name' label='Nombre' validate={required()} />
				<NumberInput source='weigth' label='Orden' validate={required()} />
				<ReferenceInput
					source='parent'
					reference='cms_categories'
					label='Padre'
					allowEmpty
				>
					<SelectInput source='name' />
				</ReferenceInput>
			</SimpleForm>
		</Create>
	)
}

export default CmsCategoryCreate;
