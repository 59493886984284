import {
    List,
    Datagrid,
    TextField,
    NumberField,
    EditButton,
    DateField,
    ReferenceField,
    TextInput,
    DateInput,
    SelectInput,
} from "react-admin";
import IconSend from "@material-ui/icons/Payment";
import { environment } from '../../../../environments/environment';
import { Button } from '@mui/material';

const SupplementList = (props) => {
    const filters = [
        <TextInput source='order.number' label='Id pedido' />,
        <DateInput
            label='Fecha de creacion anterior a'
            source='order.creationDate[before]'
        />,
        <DateInput
            label='Fecha de creacion posterior a'
            source='order.creationDate[after]'
        />,
        <TextInput source='order.shipments.number' label='Nº envío' />,
        <TextInput source='order.marketPlaces.name' label='Marca' />,
        <SelectInput source="order.type" label="Tipo pedido" choices={[
            { id: environment.orderTypes.EXTERNAL, name: 'Externo' },
            { id: environment.orderTypes.INTERNAL, name: 'Interno' },
        ]} />,
        <TextInput source='order.marketOrder.purchaseId' label='Id pedido marca' />,
        <TextInput source='order.supplement.supplement' label='Suplemento transporte' />,
        <TextInput source='payment.number' label='Nº operación pago link' />,
        <SelectInput source="payment.status" label="Estado cobro" choices={[
            { id: environment.paymentStatus.PROCESING, name: 'Procesando' },
            { id: environment.paymentStatus.CONFIRMED, name: 'Confirmado' },
            { id: environment.paymentStatus.PROCESING, name: 'Incidencia' },
        ]} />,
        <TextInput source='order.user.email' label='Mail cliente' />,
        <TextInput source='order.user.name' label='Nombre cliente' />,
        <TextInput source='order.total' label='Importe pedido Syniva' />,

    ]

    const handleSendPayment = () => {
        // TODO: Send payment to user
        console.log('Send payment to user');
    }

    return (
        /**
         * Listing Order associated to supplement
         */
        <List
            {...props}
            filters={filters}
            filter={{ 'order.status': environment.orderStatus.SUPPLEMENT }}
        >
            <Datagrid>

                {/* Id of the associated order */}
                <ReferenceField
                    label="Id pedido"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <TextField source="number" />
                </ReferenceField>

                {/* Creation date of the associated order */}
                <ReferenceField
                    label="Fecha"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <DateField source="creationDate" showTime="true" locales='es-ES' />
                </ReferenceField>

                {/* Status of the associated order */}
                <ReferenceField
                    label="Estado syniva"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <TextField source="status" />
                </ReferenceField>

                {/* Shipment number of the associated order */}
                <ReferenceField
                    label="Nº envío"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <ReferenceField
                        reference="shipments"
                        source="shipments"
                        link={false}
                    >
                        <TextField source="number" />
                    </ReferenceField>
                </ReferenceField>

                {/* Marketplace of the associated order */}
                <ReferenceField
                    label="Marca"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <ReferenceField
                        source="marketPlaces[0].@id"
                        reference="market_places"
                        link={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                </ReferenceField>

                {/* Type of the associated order */}
                <ReferenceField
                    label="Tipo pedido"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <TextField source="type" />
                </ReferenceField>

                {/* Market order id of the associated order */}
                <ReferenceField
                    label="Id pedido marca"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <ReferenceField
                        reference="market_orders"
                        source="marketOrder"
                        link={false}
                    >
                        <TextField source="purchaseId" />
                    </ReferenceField>
                </ReferenceField>

                {/* Supplement price */}
                <ReferenceField
                    label="Suplemento transporte"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <ReferenceField
                        reference="supplements"
                        source="supplement"
                        link={false}
                    >
                        <NumberField
                            source="supplement"
                            locales='es-Es'
                            options={{ style: "currency", currency: "EUR" }}
                        />
                    </ReferenceField>
                </ReferenceField>

                {/* Supplement payment number */}
                <ReferenceField
                    label="Nº operación pago link"
                    source="payment"
                    reference="payments"
                    link={false}
                >
                    <TextField source="number" />
                </ReferenceField>

                {/* supplement payment status */}
                <ReferenceField
                    label="Estado cobro"
                    source="payment"
                    reference="payments"
                    link={false}
                >
                    <TextField source="status" />
                </ReferenceField>

                {/* User email of the associated order */}
                <ReferenceField
                    label="Mail cliente"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <ReferenceField
                        reference="users"
                        source="user"
                        link={false}
                    >
                        <TextField source="email" />
                    </ReferenceField>
                </ReferenceField>

                {/* User name of the associated order */}
                <ReferenceField
                    label="Nombre cliente"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <ReferenceField
                        reference="users"
                        source="user"
                        link={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                </ReferenceField>

                {/* Price of the associated order */}
                <ReferenceField
                    label="Importe pedido syniva"
                    source="order"
                    reference="orders"
                    link={false}
                >
                    <ReferenceField
                        source='marketOrder'
                        reference='market_orders'
                        link={false}
                    >
                        <NumberField
                            source="purchaseAmount"
                            locales='es-Es'
                            options={{ style: "currency", currency: "EUR" }}
                        />
                    </ReferenceField>
                </ReferenceField>

                <Button
                    label='Pago por link'
                    onClick={handleSendPayment}
                    startIcon={<IconSend />}
                    sx={{
                        color: '#38A9B4'
                    }}
                >
                    Enviar
                </Button>

                {/* Edit button */}
                <EditButton />
            </Datagrid>
        </List>
    )
}

export default SupplementList;
