import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import MarketplaceList from './MarketplaceList/MarketplaceList';
import MarketplaceCreate from './MarketplaceCreate/MarketplaceCreate';
import MarketplaceEdit from './MarketplaceEdit/MarketPlaceEdit';

const MarketplaceResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={MarketplaceList}
			create={MarketplaceCreate}
			edit={MarketplaceEdit}
			show={null}
		/>
	);
};

MarketplaceResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default MarketplaceResource;
