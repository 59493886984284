import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import AddressCreate from './AddressCreate/AddressCreate';
import AddressList from './AddressList/AddressList';
import AddressEdit from './AdressEdit/AddressEdit';

const AddressResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={AddressList}
			create={AddressCreate}
			edit={AddressEdit}
			show={null}
		/>
	);
};

AddressResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default AddressResource;
