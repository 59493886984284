import React, { useState } from "react";
import {
  Button,
  SaveButton,
  TextInput,
  NumberInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  required,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MediaObjectQuickCreateInput from '../../Input/MediaObjectQuickCreateInput/MediaObjectQuickCreateInput';

function SupplementQuickEditButton({ onChange, order }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("supplements");
  const notify = useNotify();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    let payload = {
      weigth: values.weigth,
      length: values['length'],
      heigth: values.heigth,
      depth: values.depth,
      order: order
    }

    console.log(payload)

    create(
      { payload: { data: payload } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Crear">
        <IconContentAdd />
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Realizar compra"
      >
        <DialogTitle>Realizar compra</DialogTitle>

        <FormWithRedirect
          resource="market_orders"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <NumberInput
                  source="weigth"
                  label="Peso kg"
                  validate={required()}
                />
                <br />
                <NumberInput
                  source="length"
                  label="Largo cm"
                  validate={required()}
                />
                <br />
                <NumberInput
                  source="heigth"
                  label="Altura cm"
                  validate={required()}
                />
                <br />
                <NumberInput
                  source="depth"
                  label="Profundidad cm"
                  validate={required()}
                />
                <br />
              </DialogContent>

              <DialogActions>
                <Button
                  label="Cancelar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>

                <SaveButton
                  label="Guardar"
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default SupplementQuickEditButton;
