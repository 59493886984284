import * as React from 'react';
import { Layout, Sidebar } from 'react-admin';
import CustomAppBar from './CustomAppBar/CustomAppBar';
import CustomMenu from './CustomMenu/CustomMenu';
import CustomSidebar from './CustomSidebar/CustomSidebar';

const CustomLayout = (props) => (
    <>
        <Layout
            {...props}
            appBar={CustomAppBar}
            sidebar={Sidebar}
            // menu={CustomMenu}
        />
    </>
)

export default CustomLayout;
