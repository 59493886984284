import { Edit, SimpleForm, TextInput } from 'react-admin';

const VariableEdit = (props) => (
    <Edit {...props} title='Metodos de envio'>
        <SimpleForm redirect='list'>
            <TextInput source='name' label='Nombre' />
            <TextInput source='value' label='Valor' />
            <TextInput source='description' label='Descripcion' />
        </SimpleForm>
    </Edit>
);

export default VariableEdit;
