import {
	Edit,
	SimpleForm,
	TextInput,
	ReferenceInput,
	SelectInput,
} from 'react-admin';

const AddressEdit = (props) => (
	<Edit {...props} title='Direcciones'>
		<SimpleForm redirect='list'>
			<TextInput source='street' label='Calle' />
			<TextInput source='number' label='Número' />
			<TextInput source='additionalInfo' label='Mas info' />
			<TextInput source='city' label='Ciudad' />
			<TextInput source='postalCode' label='Código postal' />

			<ReferenceInput label='Isla' source='island' reference='islands'>
				<SelectInput optionText='name' />
			</ReferenceInput>

			<TextInput source='name' label='Nombre' />
			<TextInput source='surnames' label='Apellidos' />
			<TextInput source='email' label='Email' />
			<TextInput source='phoneNumber' label='Teléfono' />
			<TextInput source='nie' label='NIE' />

			<ReferenceInput label='Usuario' source='user' reference='users'>
				<SelectInput optionText='email' />
			</ReferenceInput>
		</SimpleForm>
	</Edit>
);

export default AddressEdit;
