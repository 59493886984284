import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import CardList from './CardList/CardList';

const CardResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={CardList}
			create={null}
			edit={null}
			show={null}
		/>
	);
};

CardResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default CardResource;
