import {
	Edit,
	SimpleForm,
	TextInput,
	NumberInput,
	ImageInput,
	ImageField,
} from 'react-admin';

const MediaObjectEdit = (props) => (
	<Edit {...props} title='Imagenes'>
		<SimpleForm redirect='list'>
			<TextInput source='name' label='Nombre' />
			<TextInput source='description' label='Descripción' />
			<TextInput source='title' label='Título' />
			<NumberInput source='size' label='Tamaño' />

			<ImageInput source='file' label='Imagen'>
				<ImageField source='src' />
			</ImageInput>
		</SimpleForm>
	</Edit>
);

export default MediaObjectEdit;
