import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import UserList from './UserList/UserList';
import UserCreate from './UserCreate/UserCreate';
import UserEdit from './UserEdit/UserEdit';

const UserResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={UserList}
			create={UserCreate}
			edit={UserEdit}
			show={null}
		/>
	);
};

UserResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default UserResource;
