import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import PaymentMethodList from './PaymentMethodList/PaymentMethodList';
import PaymentMethodCreate from './PaymentMethodCreate/PaymentMethodCreate';
import PaymentMethodEdit from './PaymentMethodEdit/PaymentMethodEdit';

const PaymentMethodResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={PaymentMethodList}
			create={PaymentMethodCreate}
			edit={PaymentMethodEdit}
			show={null}
		/>
	);
};

PaymentMethodResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default PaymentMethodResource;
