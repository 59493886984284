import {
	Edit,
	TextField,
	FormTab,
	TabbedForm,
	DateField,
	List,
	Datagrid,
	ImageField,
	NumberField,
	ReferenceField,
	ChipField,
	Button,
	SelectInput
} from "react-admin";
import "./OrderEdit.scss";
import { useRefresh } from "react-admin";
import CustomUrlField from "../../../Field/CustomUrlField/CustomUrlField.tsx";
import IconPrint from "@material-ui/icons/Print";
import SupplementQuickCreateButton from "../../../Button/SupplementQuickCreateButton/SupplementQuickCreateButton";
import { environment } from '../../../../environments/environment';
import MarketOrderQuickEditButton from '../../../Button/MarketOrderQuickCreateButton/MarketOrderQuickCreateButton';

const OrderEdit = (props) => {
	const refresh = useRefresh();

	const transform = (data) => ({
		status: data.status,
		synivaStatus: data.synivaStatus,
	});

	let number = props.id.substring(props.id.lastIndexOf("/") + 1);

	// const handlePrint = () => {
	// 	let number = props.id.substring(props.id.lastIndexOf("/") + 1);
	// 	fetch(`https://api.dev.syniva.es/invoice-pdf/${number}`)
	// 		.then((res) => {
	// 			console.log(res);
	// 			return res.blob();
	// 		})
	// 		.then((data) => {
	// 			let a = document.createElement("a");
	// 			a.href = window.URL.createObjectURL(data);
	// 			a.download = `Factura_${number}`;
	// 			a.click();
	// 		});
	// };

	return (
		<Edit {...props} title="Pedidos" transform={transform} className="printme">
			<TabbedForm>
				<FormTab label="Información pedido" className="tabs">


					<div className="basicData" style={{ width: "100%" }}>
						<div className="basicDataField">
							<span className="basicDataSpan">Número del pedido:</span>
							<TextField source="number" />
						</div>
						<div className="basicDataField">
							<span className="basicDataSpan">Estado:</span>
							<SelectInput source="status" label="Estado" choices={[
								{ id: environment.orderStatus.REQUESTED, name: 'Solicitado' },
								{ id: environment.orderStatus.PROCESSED, name: 'Procesado' },
								{ id: environment.orderStatus.CANCELED, name: 'Cancelado' },
								{ id: environment.orderStatus.RETURNED, name: 'Devolucion' },
								{ id: environment.orderStatus.SUPPLEMENT, name: 'Suplemento' },
								{ id: environment.orderStatus.ADMITED, name: 'Admitido' },
								{ id: environment.orderStatus.BREAKAGE, name: 'Rotura' },
								{ id: environment.orderStatus.PREPARED, name: 'Preparado' },
								{ id: environment.orderStatus.DISPATCHED, name: 'Expedido' },
								{ id: environment.orderStatus.SHIPPED, name: 'Enviado' },
								{ id: environment.orderStatus.DELIVERED, name: 'Entregado' },
							]} />
						</div>
						<div className="basicDataField">
							<span className="basicDataSpan">Fecha de creación:</span>
							<DateField source="creationDate" showTime locales='es-Es' />
						</div>
					</div>

					<div className='dataContainer' style={{ width: '100%' }}>
						<div className='table' style={{ width: '82.5%' }}>
							<div className="tableTitle">Resumen del pedido</div>
							<div className="tableContent">
								<div className="tableCol">
									<div className="tableField">
										<span className="tableFieldName">Usuario</span>
										<ReferenceField
											source="user"
											reference="users"
											link={false}
										>
											<TextField source="email" />
										</ReferenceField>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Marca</span>
										<TextField
											source='marketPlaces[0].name'
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Estado del pago</span>
										<TextField
											source='payments[0].status'
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Metodo de pago</span>
										<ReferenceField
											source='payments[0].method'
											reference='payment_methods'
											link={false}
										>
											<TextField source='name' />
										</ReferenceField>
									</div>
								</div>
								<div className="tableCol">
									<div className="tableField">
										<span className="tableFieldName">Numero de pago</span>
										<TextField
											source='payments[0].number'
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Nº envío</span>
										<ReferenceField
											source="shipments"
											reference="shipments"
											link={false}
										>
											<TextField source="number" />
										</ReferenceField>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Tipo de pedido</span>
										<TextField
											source="type"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Método envío</span>
										<ReferenceField
											source="shipments"
											reference="shipments"
											link={false}
										>
											<ReferenceField reference="shipment_methods" source="method" link={false}>
												<TextField source="name" />
											</ReferenceField>
										</ReferenceField>
									</div>
								</div>
								<div className="tableCol">
									<div className="tableField">
										<span className="tableFieldName">Administrador</span>
										<ReferenceField
											source="administrator"
											reference="users"
											link={false}
											emptyText='Sin datos'
										>
											<TextField source="email" />
										</ReferenceField>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Fecha de actualizacion</span>
										<DateField
											source="updatedDate"
											showTime
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="dataContainer" style={{ width: "100%" }}>
						<div className="table" style={{ width: "40%" }}>
							<div className="tableTitle">Dirección de envío</div>
							<div className="tableContent">
								<div className="tableCol">
									<div className="tableField">
										<span className="tableFieldName">Email</span>
										<TextField
											source="shippingAddress.email"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Nombre</span>
										<TextField
											source="shippingAddress.name"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Apellidos</span>
										<TextField
											source="shippingAddress.surnames"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Teléfono</span>
										<TextField
											source="shippingAddress.phoneNumber"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">NIE</span>
										<TextField
											source="shippingAddress.nie"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Isla</span>
										<TextField
											source="shippingAddress.island.name"
										/>
									</div>
								</div>
								<div className="tableCol">
									<div className="tableField">
										<span className="tableFieldName">Calle</span>
										<TextField
											source="shippingAddress.street"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Número</span>
										<TextField
											source="shippingAddress.number"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Piso/Puerta u otro</span>
										<TextField
											source="shippingAddress.additionalInfo"
										/>
									</div>
									<div className="tableField">
										<span className="addressTableFieldName">Ciudad</span>
										<TextField
											source="shippingAddress.city"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Código postal</span>
										<TextField
											source="shippingAddress.postalCode"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="table" style={{ width: "40%" }}>
							<div className="tableTitle">Dirección de facturación</div>
							<div className="tableContent">
								<div className="tableCol">
									<div className="tableField">
										<span className="tableFieldName">Email</span>
										<TextField
											source="billingAddress.email"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Nombre</span>
										<TextField
											source="billingAddress.name"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Apellidos</span>
										<TextField
											source="billingAddress.surnames"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Teléfono</span>
										<TextField
											source="billingAddress.phoneNumber"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">NIE</span>
										<TextField
											source="billingAddress.nie"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Isla</span>
										<TextField
											source="billingAddress.island.name"
										/>
									</div>
								</div>
								<div className="tableCol">
									<div className="tableField">
										<span className="tableFieldName">Calle</span>
										<TextField
											source="billingAddress.street"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Número</span>
										<TextField
											source="billingAddress.number"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Piso/Puerta u otro</span>
										<TextField
											source="billingAddress.additionalInfo"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Ciudad</span>
										<TextField
											source="billingAddress.city"
										/>
									</div>
									<div className="tableField">
										<span className="tableFieldName">Código postal</span>
										<TextField
											source="billingAddress.postalCode"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="resumeData" style={{ width: "100%" }}>
						<div className="resumeDataTable" style={{ width: "30%" }}>
							<div className="tableTitle">Resumen de compra</div>
							<div className="group">
								<div className="resumeDataTableContent">
									<div className="resumeDataTableField">
										<div>Subtotal</div>
										<NumberField
											className="resumeDataTableFieldValue"
											source="orderData.subtotal"
											locales="es-ES"
											options={{ style: "currency", currency: "EUR" }}
										/>
									</div>
									<div className="resumeDataTableField highlighted">
										<div className="resumeDataTableField">Precio syniva</div>
										<NumberField
											className="resumeDataTableFieldValue highlighted"
											source="orderData.synivaPrice"
											locales="es-ES"
											options={{ style: "currency", currency: "EUR" }}
										/>
									</div>
									<div className="resumeDataTableField highlighted">
										<div>Total ahorro</div>
										<NumberField
											className="resumeDataTableFieldValue highlighted"
											source="orderData.totalSaving"
											locales="es-ES"
											options={{ style: "currency", currency: "EUR" }}
										/>
									</div>
								</div>
								<div className="group">
									<div className="resumeDataTableField">
										<div>Envío</div>
										<NumberField
											className="resumeDataTableFieldValue"
											source="orderData.shipmentPrice"
											locales="es-ES"
											options={{ style: "currency", currency: "EUR" }}
										/>
									</div>
									<div className="resumeDataTableField">
										<div>Seguro transporte</div>
										<NumberField
											className="resumeDataTableFieldValue"
											source="orderData.transportationInsurance"
											locales="es-ES"
											options={{ style: "currency", currency: "EUR" }}
										/>
									</div>
								</div>
								<div className="group">
									<div className="resumeDataTableField highlighted">
										<div className="total">Total</div>
										<NumberField
											className="resumeDataTableFieldValue highlighted total"
											source="orderData.total"
											locales="es-ES"
											options={{ style: "currency", currency: "EUR" }}
										/>
									</div>
								</div>
							</div>
							<div className="printButton" style={{ width: "100%" }}>
								<Button
									label="Imprimir factura"
									onClick={null}
									target='_blank'
									href={`https://api.dev.syniva.es/invoice-pdf/${number}`}
								>
									<IconPrint />
								</Button>
							</div>
						</div>
					</div>
				</FormTab>
				<FormTab label="Artículos">
					<List
						{...props}
						exporter={false}
						className="itemsDataTable"
						resource="items"
						perPage={5}
						filter={{ order: props.id }}
						title=" "
					>
						<Datagrid>
							<ImageField source="image" label="Imagen" />
							<ReferenceField
								label="Marca"
								source="marketPlace"
								reference="market_places"
								link={false}
							>
								<ChipField source="name" />
							</ReferenceField>
							<TextField source="name" label="Modelo" />
							<TextField source="size" label="Talla" />
							<TextField source="" label="Color" />
							<TextField source="quantity" label="Ud" />

							<NumberField
								source="price"
								label="Precio ud"
								locales="es-ES"
								options={{ style: "currency", currency: "EUR" }}
							/>

							<ReferenceField
								label="Subtotal"
								source="order"
								reference="orders"
								link={false}
							>
								<NumberField
									source="orderData.subtotal"
									locales="es-ES"
									options={{ style: "currency", currency: "EUR" }}
								/>
							</ReferenceField>

							<CustomUrlField source="url" label="Url" />
						</Datagrid>
					</List>
				</FormTab>
				<FormTab label="Compra">
					<List
						{...props}
						exporter={false}
						className="itemsDataTable"
						resource="market_orders"
						perPage={5}
						filter={{ order: props.id }}
						title=" "
					>
						<Datagrid>
							<ReferenceField
								label="Id pedido marca"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="market_orders" source="marketOrder" link={false}>
									<TextField source="purchaseId" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Id operación bancaria"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="market_orders" source="marketOrder" link={false}>
									<TextField source="transactionId" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Importe compra"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="market_orders" source="marketOrder" link={false}>
									<NumberField
										source="purchaseAmount"
										locales="es-ES"
										options={{ style: "currency", currency: "EUR" }}
									/>
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Nº seguimiento envío"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="market_orders" source="marketOrder" link={false}>
									<TextField source="trackingId" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Nº factura"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="market_orders" source="marketOrder" link={false}>
									<TextField source="invoiceNumber" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Factura"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="market_orders" source="marketOrder" link={false}>
									<ReferenceField reference="media_objects" source="invoice" link={false}>
										<CustomUrlField prefix={environment.apiUrl} source='contentUrl' />
									</ReferenceField>
								</ReferenceField>
							</ReferenceField>
						</Datagrid>
					</List>
					<div className="itemsDataActions">
						<MarketOrderQuickEditButton onChange={refresh} order={props.id} />
					</div>
				</FormTab>
				<FormTab label="Suplemento volumétrico transporte">
					<List
						{...props}
						exporter={false}
						className="itemsDataTable"
						resource="supplements"
						perPage={5}
						filter={{ order: props.id }}
						title=" "
					>
						<Datagrid>
							<ReferenceField
								label="Peso neto kg"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="supplements" source="supplement" link={false}>
									<TextField source="weigth" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Largo cm"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="supplements" source="supplement" link={false}>
									<TextField source="length" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Alto cm"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="supplements" source="supplement" link={false}>
									<TextField source="heigth" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Fondo cm"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="supplements" source="supplement" link={false}>
									<TextField source="depth" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Volumen"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="supplements" source="supplement" link={false}>
									<TextField source="volume" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Volumen facturable"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="supplements" source="supplement" link={false}>
									<TextField source="billableVolume" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Exceso kg"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="supplements" source="supplement" link={false}>
									<TextField source="weigthExcess" />
								</ReferenceField>
							</ReferenceField>

							<ReferenceField
								label="Suplemento"
								source="order"
								reference="orders"
								link={false}
							>
								<ReferenceField reference="supplements" source="supplement" link={false}>
									<NumberField
										source="supplement"
										locales="es-ES"
										options={{ style: "currency", currency: "EUR" }}
									/>
								</ReferenceField>
							</ReferenceField>

						</Datagrid>
					</List>

					<div className="itemsDataActions">
						<SupplementQuickCreateButton onChange={refresh} order={props.id} />
					</div>
				</FormTab>
			</TabbedForm>

			{/* <SimpleForm>
        {/* COMENTAN EN EL EXCEL QUE NO VEN NECESARIO QUE APAREZCA NI DIRECCIÓN DE ENVÍO NI FACTURACIÓN
        TAMPOCO EL HISTÓRICO
        
        <div className="basicData" style={{ width: "100%" }}>
          <div className="basicDataField">
            <span className="basicDataSpan">Número del pedido:</span>
            <TextField source="number" />
          </div>
          <div className="basicDataField">
            <span className="basicDataSpan">Estado:</span>
            <SelectInput
              source="status"
              choices={[
                { id: "confirmed", name: "Confirmado" },
                { id: "pending", name: "Pendiente" },
                { id: "error", name: "Error" },
              ]}
            />
          </div>
          <div className="basicDataField">
            <span className="basicDataSpan">Estado Syniva:</span>
            <SelectInput
              source="synivaStatus"
              choices={[
                { id: "confirmed", name: "Confirmado" },
                { id: "pending", name: "Pendiente" },
                { id: "error", name: "Error" },
              ]}
            />
          </div>
          <div className="basicDataField">
            <span className="basicDataSpan">Fecha de creación:</span>
            <DateField source="creationDate" />
          </div>
        </div>
        <div className="addressData" style={{ width: "100%" }}>
          <div className="addressTable">
            <div className="addressTableTitle">Dirección de envío</div>
            <div className="addressTableContent">
              <div className="addressTableRow">
                <div className="addressTableField">
                  <span className="addressTableFieldName">Email</span>
                  <TextField
                    source="shippingAddress.email"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Nombre</span>
                  <TextField
                    source="shippingAddress.name"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Apellidos</span>
                  <TextField
                    source="shippingAddress.surnames"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Teléfono</span>
                  <TextField
                    source="shippingAddress.phoneNumber"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">NIE</span>
                  <TextField
                    source="shippingAddress.nie"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Isla</span>
                  <TextField
                    source="shippingAddress.island.name"
                    style={addressTableFieldValueStyle}
                  />
                </div>
              </div>
              <div className="addressTableRow">
                <div className="addressTableField">
                  <span className="addressTableFieldName">Calle</span>
                  <TextField
                    source="shippingAddress.street"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Número</span>
                  <TextField
                    source="shippingAddress.number"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Piso</span>
                  <TextField
                    source="shippingAddress.floor"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Puerta</span>
                  <TextField
                    source="shippingAddress.door"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Ciudad</span>
                  <TextField
                    source="shippingAddress.city"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Código postal</span>
                  <TextField
                    source="shippingAddress.postalCode"
                    style={addressTableFieldValueStyle}
                  />
                </div>
              </div>
            </div>
            <div className="AddresTableEditButton">
              <AddressQuickEditButton
                onChange={refresh}
                type="shippingAddress"
              />
            </div>
          </div>

          <div className="addressTable">
            <div className="addressTableTitle">Dirección de facturación</div>
            <div className="addressTableContent">
              <div className="addressTableRow">
                <div className="addressTableField">
                  <span className="addressTableFieldName">Email</span>
                  <TextField
                    source="billingAddress.email"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Nombre</span>
                  <TextField
                    source="billingAddress.name"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Apellidos</span>
                  <TextField
                    source="billingAddress.surnames"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Teléfono</span>
                  <TextField
                    source="billingAddress.phoneNumber"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">NIE</span>
                  <TextField
                    source="billingAddress.nie"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Isla</span>
                  <TextField
                    source="billingAddress.island.name"
                    style={addressTableFieldValueStyle}
                  />
                </div>
              </div>
              <div className="addressTableRow">
                <div className="addressTableField">
                  <span className="addressTableFieldName">Calle</span>
                  <TextField
                    source="billingAddress.street"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Número</span>
                  <TextField
                    source="billingAddress.number"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Piso</span>
                  <TextField
                    source="billingAddress.floor"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Puerta</span>
                  <TextField
                    source="billingAddress.door"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Ciudad</span>
                  <TextField
                    source="billingAddress.city"
                    style={addressTableFieldValueStyle}
                  />
                </div>
                <div className="addressTableField">
                  <span className="addressTableFieldName">Código postal</span>
                  <TextField
                    source="billingAddress.postalCode"
                    style={addressTableFieldValueStyle}
                  />
                </div>
              </div>
            </div>
            <div className="AddresTableEditButton">
              <AddressQuickEditButton
                onChange={refresh}
                type="billingAddress"
              />
            </div>
          </div>
        </div> */}

			{/* 
        <div className="historyData" style={{ width: "100%" }}>
          <div className="historyDataTitle">Historial</div>
          <div className="historyDataContent">
            <List
              {...props}
              exporter={false}
              resource="order_histories"
              perPage={5}
              title=" "
              filter={{ order: props.id }}
            >
              <Datagrid>
                <DateField source="date" label="Fecha" />
                <TextField source="note" label="Comentario" />
                <ReferenceField
                  reference="users"
                  source="user"
                  label="Usuario"
                  link={false}
                >
                  <ChipField source="name" />
                </ReferenceField>
              </Datagrid>
            </List>
          </div>
          <div className="historyDataActions">
            <OrderHistoryQuickCreateButton
              onChange={refresh}
              order={props.id}
            />
          </div>
        </div> */}
			{/* </SimpleForm> */}
		</Edit >
	);
};

export default OrderEdit;
