import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import IslandList from './IslandList/IslandList';
import IslandCreate from './IslandCreate/IslandCreate';
import IslandEdit from './IslandEdit/IslandEdit';

const IslandResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={IslandList}
			create={IslandCreate}
			edit={IslandEdit}
			show={null}
		/>
	);
};

IslandResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default IslandResource;
