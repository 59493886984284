import {
	List,
	Datagrid,
	TextField,
	ArrayField,
	SingleFieldList,
	BooleanField,
	ReferenceField,
	ChipField,
	TextInput,
	BooleanInput,
	EditButton,
} from 'react-admin';
import CustomChipField from '../../../Field/CustomChipField/CustomChipField.tsx';

const filters = [
	<TextInput source='name' label='Nombre' />,
	<TextInput source='email' label='Email' />,
	<TextInput source='roles' label='Roles' />,
	<TextInput
		source='defaultBillingAddress.city'
		label='Direccion de facturacion favorita'
	/>,
	<TextInput
		source='defaultShippingAddress.city'
		label='Dirección de envio favorita'
	/>,
	<BooleanInput source='active' label='Activo' />,
];

const UserList = (props) => (
	<List {...props} filters={filters}>
		<Datagrid>
			<TextField source='name' label='Nombre' />
			<TextField source='email' label='Email' />

			<ArrayField source='roles' label='Roles'>
				<SingleFieldList linkType={false}>
					<CustomChipField />
				</SingleFieldList>
			</ArrayField>

			<ReferenceField
				source='defaultBillingAddress'
				reference='addresses'
				label='Direccion de facturacion favorita'
			>
				<ChipField source='city' />
			</ReferenceField>

			<ReferenceField
				source='DefaultShippingAddress'
				reference='addresses'
				label='Dirección de envio favorita'
			>
				<ChipField source='city' />
			</ReferenceField>

			<BooleanField source='active' label='Activo' />
			<EditButton label='Editar' />
		</Datagrid>
	</List>
);

export default UserList;
