import {
  ReferenceInput,
  TextInput,
  SelectInput,
  Edit,
  SimpleForm,
  required,
  Toolbar,
  useGetOne,
} from "react-admin";
import JoditEditor from "jodit-react";
import { useRef, useState } from 'react';

const CmsEdit = (props) => {
  const { data: record, loading } = useGetOne('cms', props.id);
  let cmsData = loading ? '' : record.content;
  const [toolbar, setToolbar] = useState(<Toolbar />);
  const newData = useRef('');

  const transform = (data) => ({
    ...data,
    content: newData.current,
  });

  return (
    <Edit {...props} transform={transform} title="CMS">
      <SimpleForm redirect="list" toolbar={toolbar}>
        <TextInput source="name" label="Nombre" validate={required()} />
        <JoditEditor
          value={cmsData}
          onBlur={(content) => {
            newData.current = content;

            setToolbar(<Toolbar alwaysEnableSaveButton />);
          }}
        />

        <ReferenceInput
          label="Categoría"
          source="category"
          reference="cms_categories"
          validate={required()}
        >
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default CmsEdit;
