import React, { useState } from 'react';
import {
	required,
	Button,
	SaveButton,
	TextInput,
	FileInput,
	FileField,
	NumberInput,
	useCreate,
	useNotify,
	FormWithRedirect,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function MediaObjectQuickCreateButton({size = true, onChange }) {
	const [showDialog, setShowDialog] = useState(false);
	const [create, { loading }] = useCreate('media_objects');
	const notify = useNotify();
	const handleClick = () => {
		setShowDialog(true);
	};

	const handleCloseClick = () => {
		setShowDialog(false);
	};

	const handleSubmit = async (values) => {
		create(
			{ payload: { data: values } },
			{
				onSuccess: ({ data }) => {
					setShowDialog(false);
					onChange();
				},
				onFailure: ({ error }) => {
					notify(error.message, 'error');
				},
			}
		);
	};

	return (
		<>
			<Button onClick={handleClick} label='Crear'>
				<IconContentAdd />
			</Button>

			<Dialog
				fullWidth
				open={showDialog}
				onClose={handleCloseClick}
				aria-label='Crear archivo'
			>
				<DialogTitle>Crear Archivo</DialogTitle>

				<FormWithRedirect
					resource='media_objects'
					save={handleSubmit}
					render={({ handleSubmitWithRedirect, pristine, saving }) => (
						<>
							<DialogContent>
								<TextInput
									source='description'
									label='Descripción'
									validate={required()}
								/>
								<br />
								<TextInput
									source='title'
									label='Título'
									validate={required()}
								/>
								<br />
								<TextInput source='name' label='Nombre' validate={required()} />
								<br />
								{size ? (
									<NumberInput
										source='size'
										label='Tamaño'
										validate={required()}
									/>
								) : (
									<NumberInput
										source='size'
										defaultValue={0}
										style={{visibility: 'hidden'}}
									/>
								)}
								
								<FileInput source='file' label='Archivo' validate={required()}>
									<FileField source='src' title='title' />
								</FileInput>
							</DialogContent>

							<DialogActions>
								<Button
									label='Cancelar'
									onClick={handleCloseClick}
									disabled={loading}
								>
									<IconCancel />
								</Button>

								<SaveButton
									label='Guardar'
									handleSubmitWithRedirect={handleSubmitWithRedirect}
									pristine={pristine}
									saving={saving}
									disabled={loading}
								/>
							</DialogActions>
						</>
					)}
				/>
			</Dialog>
		</>
	);
}

export default MediaObjectQuickCreateButton;
