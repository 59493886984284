import { Edit, SimpleForm, BooleanInput } from 'react-admin';
import QuickCreateReferenceInput from '../../../Input/MediaObjectQuickCreateInput/MediaObjectQuickCreateInput';

const BannerEdit = (props) => {
	const transform = (data) => ({
		...data,
		image: data.image['@id'],
	});

	return (
		<Edit {...props} transform={transform} title='Banners'>
			<SimpleForm redirect='list'>
				<QuickCreateReferenceInput
					source='image.@id'
					label='Imagen'
					reference='media_objects'
				/>
				<BooleanInput source='isInHome' label='Está en Home' />
			</SimpleForm>
		</Edit>
	);
};

export default BannerEdit;
