import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import CmsList from './CmsList/CmsList';
import CmsCreate from './CmsCreate/CmsCreate';
import CmsEdit from './CmsEdit/CmsEdit';

const CmsResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={CmsList}
			create={CmsCreate}
			edit={CmsEdit}
			show={null}
		/>
	);
};

CmsResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default CmsResource;
