import {
	List,
	Datagrid,
	TextField,
	DateField,
	NumberField,
	ChipField,
	ReferenceField,
	SelectArrayInput,
	DateInput,
	NumberInput,
	TextInput,
} from 'react-admin';

const filters = [
	<TextInput source='number' label='Número' />,
	<SelectArrayInput
		label='Estado'
		source='status'
		choices={[
			{ id: 'pending', name: 'Pendiente' },
			{ id: 'confirmed', name: 'Confirmado' },
			{ id: 'error', name: 'Error' },
		]}
	/>,
	<DateInput source='paymentDate[before]' label='Fecha del pago anterior a' />,
	<DateInput source='paymentDate[after]' label='Fecha del pago posterior a' />,
	<NumberInput source='amount' label='Cantidad' />,
	<TextInput source='user.email' label='Usuario' />,
	<NumberField source='order.number' label='Pedido' />,
];

const PaymentList = (props) => (
	<List {...props} filters={filters}>
		<Datagrid>
			<TextField source='number' label='Número' />
			<TextField source='status' label='Estado' />
			<DateField source='paymentDate' label='Fecha del pago' />
			<NumberField 
				source='amount' 
				label='Cantidad' 
				locales='es-ES'
				options={{style: 'currency', currency: 'EUR'}}
			/>

			<ReferenceField source='user' reference='users' label='Usuario'>
				<ChipField source='email' />
			</ReferenceField>

			<ReferenceField source='order' reference='orders' label='Pedido'>
				<ChipField source='number' />
			</ReferenceField>
		</Datagrid>
	</List>
);

export default PaymentList;
