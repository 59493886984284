import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	ChipField,
	TextInput,
	SelectArrayInput,
	EditButton,
} from 'react-admin';

const filters = [
	<TextInput label='Calle' source='street' />,
	<TextInput label='Número' source='number' />,
	<TextInput label='Piso' source='floor' />,
	<TextInput label='Puerta' source='door' />,
	<TextInput label='Ciudad' source='city' />,
	<TextInput label='Código Postal' source='postalCode' />,
	<TextInput label='Nombre' source='name' />,
	<TextInput label='Apellidos' source='surnames' />,
	<TextInput label='Email' source='email' />,
	<TextInput label='Teléfono' source='phoneNumber' />,
	<SelectArrayInput
		label='Isla'
		source='island.name'
		choices={[
			{ id: 'Tenerife', name: 'Tenerife' },
			{ id: 'Fuerteventura', name: 'Fuerteventura' },
			{ id: 'Lanzarote', name: 'Lanzarote' },
			{ id: 'La Gomera', name: 'La Gomera' },
			{ id: 'El Hierro', name: 'El Hierro' },
			{ id: 'La Palma', name: 'La Palma' },
			{ id: 'Gran Canaria', name: 'Gran Canaria' },
		]}
	/>,
	<TextInput label='Usuario' source='user.email' />,
];

const AddressList = (props) => (
	<List {...props} filters={filters} title='Direcciones'>
		<Datagrid>
			<TextField source='street' label='Calle' />
			<TextField source='number' label='Número' />
			<TextField source='additionalInfo' label='Mas info' />
			<TextField source='city' label='Ciudad' />
			<TextField source='postalCode' label='Código Postal' />
			<TextField source='name' label='Nombre' />
			<TextField source='surnames' label='Apelldios' />
			<TextField source='email' label='Email' />
			<TextField source='phoneNumber' label='Teléfono' />

			<ReferenceField label='Isla' source='island' reference='islands'>
				<ChipField source='name' />
			</ReferenceField>

			<ReferenceField label='Usuario' source='user' reference='users'>
				<ChipField source='email' />
			</ReferenceField>
			<EditButton label='Editar' />
		</Datagrid>
	</List>
);

export default AddressList;
