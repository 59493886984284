import { List, Datagrid, TextField } from 'react-admin';

const PaymentMethodList = (props) => (
	<List {...props}>
		<Datagrid>
			<TextField source='name' label="Nombre" />
		</Datagrid>
	</List>
);

export default PaymentMethodList;
