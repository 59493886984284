import React from "react";
import { HydraAdmin } from "@api-platform/admin";
import { environment } from './environments/environment';
import AuthProvider from "./utils/AuthProvider";
import DataProvider from "./utils/DataProvider";

/* Resources */

import AddressResource from "./components/Resource/Address/AddressResource";
import BannerResource from "./components/Resource/Banner/BannerResource";
import CardResource from "./components/Resource/Card/CardResource";
import IslandResource from "./components/Resource/Island/IslandResource";
import ItemResource from "./components/Resource/Item/ItemResource";
import MarketplaceResource from "./components/Resource/Marketplace/MarketplaceResource";
import MarketplaceCategoryResource from "./components/Resource/MarketplaceCategory/MarketplaceCategoryResource";
import MediaObjectResource from "./components/Resource/MediaObject/MediaObjectResource";
import OrderResource from "./components/Resource/Order/OrderResource";
import PaymentResource from "./components/Resource/Payment/PaymentResource";
import ShipmentResource from "./components/Resource/Shipment/ShipmentResource";
import PaymentMethodResource from './components/Resource/PaymentMethod/PaymentMethodResource';
import ShipmentMethodResource from "./components/Resource/ShipmentMethod/ShipmentMethodResource";
import UserResource from "./components/Resource/User/UserResource";
import CustomLayout from "./components/CustomLayout/CustomLayout";
import OrderHistoryResource from "./components/Resource/OrderHistory/OrderHistoryResource";
import CmsCategoryResource from "./components/Resource/CmsCategory/CmsCategoryResource";
import CmsResource from "./components/Resource/Cms/CmsResource";
import VariableResource from "./components/Resource/Variable/VariableResource";
import SupplementResource from "./components/Resource/Supplement/SupplementResource";
import MarketOrderResource from "./components/Resource/MarketOrder/MarketOrderResource";

const LastsmileAdmin = () => {
  return (
    <HydraAdmin
      layout={CustomLayout}
      entrypoint={environment.entrypoint}
      authProvider={AuthProvider}
      dataProvider={DataProvider}
    >
      <AddressResource name="addresses" options={{ label: "Direcciones" }} />
      <BannerResource name="banners" options={{ label: "Banners" }} />
      <CardResource name="cards" options={{ label: "Tarjetas" }} />
      <IslandResource name="islands" options={{ label: "Islas" }} />
      <ItemResource name="items" options={{ label: "Artículos" }} />
      <MarketplaceResource
        name="market_places"
        options={{ label: "Tiendas" }}
      />
      <MarketplaceCategoryResource
        name="market_place_categories"
        options={{ label: "Categorías" }}
      />
      <MediaObjectResource
        name="media_objects"
        options={{ label: "Imágenes" }}
      />
      <OrderResource name="orders" options={{ label: "Pedidos" }} />
      {/* <OrderAdmitedResource name="orders/admitted" options={{ label: "Gestion de envios" }} /> */}
      <PaymentResource name="payments" options={{ label: "Pagos" }} />
      <PaymentMethodResource name="payment_methods" options={{ label: "Metodos de pago" }} />
      <ShipmentResource name="shipments" options={{ label: "Envíos" }} />
      <ShipmentMethodResource
        name="shipment_methods"
        options={{ label: "Métodos de envío" }}
      />
      <UserResource name="users" options={{ label: "Usuarios" }} />
      <OrderHistoryResource
        name="order_histories"
        options={{ label: "Comentarios" }}
      />
      <CmsCategoryResource
        name="cms_categories"
        options={{ label: "CMS categorías" }}
      />
      <CmsResource name="cms" options={{ label: "CMS" }} />
      <VariableResource name="variables" options={{ label: "Variables" }} />
      <SupplementResource
        name="supplements"
        options={{ label: "Suplementos" }}
      />
      <MarketOrderResource
        name="market_orders"
        options={{ label: "Gestion de envios" }}
      />
    </HydraAdmin>
  );
};

export default LastsmileAdmin;
