import {
  List,
  Datagrid,
  TextField,
  NumberField,
  TextInput,
  NumberInput,
  EditButton,
} from "react-admin";

const filters = [
  <TextInput source="name" label="Nombre" />,
  <NumberInput source="price" label="Precio" />,
];

const ShipmentMethodList = (props) => (
  <List {...props} filters={filters}>
    <Datagrid>
      <TextField source="name" label="Nombre" />
      <NumberField
        source="price"
        label="Precio"
        locales="es-ES"
        options={{ style: "currency", currency: "EUR" }}
      />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
);

export default ShipmentMethodList;
