import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	ChipField,
	TextInput,
	EditButton,
} from 'react-admin';

const filters = [
	<TextInput source='name' label='Nombre' />,
	<TextInput source='description' label='Descripción' />,
];

const MarketplaceCategoryList = (props) => (
	<List {...props} filters={filters}>
		<Datagrid>
			<TextField source='name' label='Nombre' />
			<TextField source='description' label='Descripción' />

			<ReferenceField
				label='Padre'
				source='parentIri'
				reference='market_place_categories'
			>
				<ChipField source='name' />
			</ReferenceField>
			<EditButton label='Editar' />
		</Datagrid>
	</List>
);

export default MarketplaceCategoryList;
