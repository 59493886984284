import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import OrderList from './OrderList/OrderList';
import OrderEdit from './OrderEdit/OrderEdit';

const OrderResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={OrderList}
			create={null}
			edit={OrderEdit}
			show={null}
		/>
	);
};

OrderResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default OrderResource;
