import {
    List,
    Datagrid,
    NumberField,
    ReferenceField,
    TextField,
    DateField,
    FunctionField,
    DateInput,
    TextInput,
    SelectInput,
} from "react-admin";
import { environment } from '../../../../environments/environment';
import ProcessButton from '../../../Button/ProcessButton/ProcessButton';

const MarketOrderList = (props) => {
    const filters = [
        <TextInput source='order.number' label='Id pedido' />,
        <DateInput
            label='Fecha de creacion anterior a'
            source='order.creationDate[before]'
        />,
        <DateInput
            label='Fecha de creacion posterior a'
            source='order.creationDate[after]'
        />,
        <TextInput source='order.user.email' label='Id cliente' />,
        <TextInput source='order.shipments.number' label='Nº envío' />,
        <TextInput source='order.marketPlaces.name' label='Marca' />,
        <SelectInput source="order.type" label="Tipo pedido" choices={[
            { id: environment.orderTypes.EXTERNAL, name: 'Externo' },
            { id: environment.orderTypes.INTERNAL, name: 'Interno' },
        ]} />,
        <TextInput source='order.shipments.method.name' label='Metodo de envio' />,
        <TextInput source='order.total' label='Importe total Syniva' />,
        <TextInput source='order.marketOrder.purchaseId' label='Id pedido marca' />,
        <TextInput source='order.shippingAddress.name' label='Nombre cliente' />,
        <TextInput source='order.shippingAddress.surnames' label='Apellidos cliente' />,
        // TODO: direccion filter
        <TextInput source='direccion' label='Direccion' />,
        <TextInput source='order.shippingAddress.postalCode' label='CP' />,
        <TextInput source='order.shippingAddress.city' label='Poblacion' />,
    ]

    return (
        /**
         * Listing Order associated to marketOrder
         */
        <List
            {...props}
            filters={filters}
            filter={{ 'order.status': environment.orderStatus.ADMITED }}
        >
            <Datagrid>

                {/* Associated order id */}
                <ReferenceField
                    label='Id pedido'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <TextField source='number' />
                </ReferenceField>

                {/* Assciated order creationDate */}
                <ReferenceField
                    label='Fecha'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <DateField
                        source='creationDate'
                        showTime
                        locales='es-ES'
                    />
                </ReferenceField>

                {/* Associated order status */}
                <ReferenceField
                    label='Estado syniva'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <TextField source='status' />
                </ReferenceField>

                {/* Associated order user email */}
                <ReferenceField
                    label='Id cliente'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <ReferenceField
                        source='user'
                        reference='users'
                        link={false}
                    >
                        <TextField source='email' />
                    </ReferenceField>
                </ReferenceField>

                {/* Associated order shipment number */}
                <ReferenceField
                    label='Nº envío'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <ReferenceField
                        source='shipments'
                        reference='shipments'
                        link={false}
                    >
                        <TextField source='number' />
                    </ReferenceField>
                </ReferenceField>

                {/* Associated order marketplace */}
                <ReferenceField
                    label='Marca'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <ReferenceField
                        source='marketPlaces[0].@id'
                        reference='market_places'
                        link={false}
                    >
                        <TextField source='name' />
                    </ReferenceField>
                </ReferenceField>

                {/* Associated order type */}
                <ReferenceField
                    label='Tipo pedido'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <TextField source='type' />
                </ReferenceField>

                {/* Associated order shipment method */}
                <ReferenceField
                    label='Metodo de envio'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <ReferenceField
                        source='shipments'
                        reference='shipments'
                        link={false}
                    >
                        <ReferenceField
                            source='method'
                            reference='shipment_methods'
                            link={false}
                        >
                            <TextField source='name' />
                        </ReferenceField>
                    </ReferenceField>
                </ReferenceField>

                {/* Order total */}
                <ReferenceField
                    source='order'
                    label='Importe total Syniva'
                    reference='orders'
                    link={false}
                >
                    <NumberField
                        source='total'
                        locales='es-Es'
                        options={{ style: "currency", currency: "EUR" }}
                    />
                </ReferenceField>

                {/* Associated order marketOrder id */}
                <ReferenceField
                    label='Id pedido marca'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <ReferenceField
                        source='marketOrder'
                        reference='market_orders'
                        link={false}
                    >
                        <TextField source='purchaseId' />
                    </ReferenceField>
                </ReferenceField>

                {/* Associated order shippingAddress name */}
                <ReferenceField
                    label='Nombre cliente'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <TextField source='shippingAddress.name' />
                </ReferenceField>

                {/* Associated order shippingAddress surnames */}
                <ReferenceField
                    label='Apellidos cliente'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <TextField source='shippingAddress.surnames' />
                </ReferenceField>

                {/* Associated order shippingAddress address */}
                <ReferenceField
                    label='Direccion'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <FunctionField render={
                        record => `
                            ${record.shippingAddress.street}
                            ${record.shippingAddress.number}
                            ${record.shippingAddress.additionalInfo}
                        `
                    } />
                </ReferenceField>

                {/* Associated order shippingAddress zip code */}
                <ReferenceField
                    label='CP'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <TextField source='shippingAddress.postalCode' />
                </ReferenceField>

                {/* Associated order shippingAddress city */},
                <ReferenceField
                    label='Poblacion'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <TextField source='shippingAddress.city' />
                </ReferenceField>

                {/* Process button */}
                <ReferenceField
                    label='Procesar'
                    source='order'
                    reference='orders'
                    link={false}
                >
                    <FunctionField render={
                        record => <ProcessButton label='Procesar' order={record} />
                    } />
                </ReferenceField>
            </Datagrid>
        </List>
    )
};

export default MarketOrderList;
