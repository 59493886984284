import {
	List,
	Datagrid,
	TextField,
	NumberField,
	ReferenceField,
	ChipField,
	ImageField,
	TextInput,
	NumberInput,
} from 'react-admin';
import CustomUrlField from '../../../Field/CustomUrlField/CustomUrlField.tsx';

const filters = [
	<TextInput source='name' label='Nombre' />,
	<TextInput source='description' label='Descripción' />,
	<NumberInput source='price' label='Precio' />,
	<TextInput source='size' label='Tamaño' />,
	<TextInput source='code' label='Código' />,
	<NumberInput source='order.number' label='Número de pedido' />,
	<TextInput source='marketPlace.name' label='Tienda' />,
];

const ItemList = (props) => (
	<List {...props} filters={filters}>
		<Datagrid>
			<ImageField source='image' label='Imagen' />
			<TextField source='name' label='Nombre' />
			<TextField source='description' label='Descripción' />
			<NumberField 
				source='price' 
				label='Precio' 
				locales='es-ES'
				options={{style: 'currency', currency: 'EUR'}}
			/>
			<TextField source='size' label='Tamaño' />
			<TextField source='code' label='Código' />

			<ReferenceField
				label='Número de pedido'
				source='order'
				reference='orders'
			>
				<ChipField source='number' />
			</ReferenceField>

			<ReferenceField
				label='Tienda'
				source='marketPlace'
				reference='market_places'
			>
				<ChipField source='name' />
			</ReferenceField>
			<CustomUrlField source='url' label='Url' />
		</Datagrid>
	</List>
);

export default ItemList;
