import React, { useState } from "react";
import {
    Button,
    SaveButton,
    NumberInput,
    useUpdate,
    useNotify,
    FormWithRedirect,
    required,
    SelectInput
} from "react-admin";
import IconProcess from "@material-ui/icons/Settings";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { environment } from '../../../environments/environment';

function ProcessButton({ onChange, order }) {
    const [showDialog, setShowDialog] = useState(false);
    const [update, { loading, error }] = useUpdate();
    const notify = useNotify();
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async (values) => {
        console.log({ values: values });
        await update('shipments', order.shipments[0], {
            type: values.type,
        });

        if (error) {
            notify(error.message, 'error');
        } else {
            setShowDialog(false);
            setTimeout(() => {
                onChange();
            }, 500);
        }
    };

    return (
        <>
            <Button onClick={handleClick} label="Procesar">
                <IconProcess />
            </Button>

            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Realizar compra"
            >
                <DialogTitle>Procesar pedido</DialogTitle>

                <FormWithRedirect
                    resource="shipments"
                    save={handleSubmit}
                    render={({ handleSubmitWithRedirect, pristine, saving }) => (
                        <>
                            <DialogContent>
                                <SelectInput
                                    source="type"
                                    label="Tipo salida"
                                    validate={required()}
                                    choices={[
                                        { id: environment.shipmentType.DIRECT, name: 'Directo' },
                                        { id: environment.shipmentType.CONSOLIDATED, name: 'Consolidado' },
                                    ]}
                                />
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    label="Cancelar"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>

                                <SaveButton
                                    label="Guardar"
                                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default ProcessButton;
