// in authProvider.js
import decodeJwt from 'jwt-decode';
import { environment } from '../environments/environment';

const AuthProvider = {
	login: ({ username, password }) => {
		const request = new Request(environment.entrypointAuth, {
			method: 'POST',
			body: JSON.stringify({ email: username, password }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});
		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then((auth) => {
				const decodedToken = decodeJwt(auth.token)
				if (decodedToken.roles.includes('ROLE_SUPER_ADMIN')) {
					localStorage.setItem('permissions', decodedToken.roles);
					localStorage.setItem(
						'auth',
						JSON.stringify(auth.token).replaceAll('"', '')
					);
				} else {
					throw new Error();
				}
			})
			.catch(() => {
				throw new Error('Network Error');
			});
	},

	checkError: (error) => {
		const status = error.status;
		if (status === 401 || status === 403) {
			localStorage.removeItem('auth');
			localStorage.removeItem('permissions');
			return Promise.reject();
		}
		return Promise.resolve();
	},

	checkAuth: () =>
		localStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),

	logout: () => {
		localStorage.removeItem('auth');
		localStorage.removeItem('permissions');
		return Promise.resolve();
	},

	getPermissions: () => {
		return localStorage.getItem('auth') && localStorage.getItem('permissions') === 'ROLE_SUPER_ADMIN'
			? Promise.resolve()
			: Promise.reject();
	}
};

export default AuthProvider;
