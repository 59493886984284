import {
	Edit,
	SimpleForm,
	TextInput,
	ReferenceInput,
	SelectInput,
} from 'react-admin';

const MarketPlaceCategoryEdit = (props) => {
	const transform = (data) => ({
		...data,
		parent: data.parentIri,
	});

	return (
		<Edit {...props} transform={transform} title='Categorias'>
			<SimpleForm redirect='list'>
				<TextInput source='name' label='Nombre' />
				<TextInput source='description' label='Descripción' />

				<ReferenceInput
					source='parentIri'
					reference='market_place_categories'
					label='Padre'
					allowEmpty
				>
					<SelectInput source='name' />
				</ReferenceInput>
			</SimpleForm>
		</Edit>
	);
};

export default MarketPlaceCategoryEdit;
