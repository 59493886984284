import {
	Edit,
	SimpleForm,
	TextInput,
	PasswordInput,
	SelectArrayInput,
	BooleanInput,
} from 'react-admin';

const UserEdit = (props) => (
	<Edit {...props} title='Usuarios'>
		<SimpleForm redirect='list'>
			<TextInput source='name' label='Nombre' />
			<TextInput source='email' label='Email' />
			<PasswordInput source='plainPassword' label='Contraseña' />

			<SelectArrayInput
				source='roles'
				label='Roles'
				choices={[
					{ id: 'ROLE_USER', name: 'ROLE_USER' },
					{ id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
					{ id: 'ROLE_SUPER_ADMIN', name: 'ROLE_SUPER_ADMIN' },
				]}
			/>

			<BooleanInput source='active' label='Activo' />
			<BooleanInput source='isGuest' label='Invitado' />
		</SimpleForm>
	</Edit>
);

export default UserEdit;
