import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	ChipField,
	TextInput,
	EditButton,
	RichTextField,
} from 'react-admin';

const filters = [
	<TextInput label='Nombre' source='name' />,
	<TextInput label='Categoría' source='category.name' />,
];

const CmsList = (props) => (
	<List {...props} filters={filters} title='CMS'>
		<Datagrid>
			<TextField source='name' label='Nombre' />

			<RichTextField source='content' label='Contenido' />

			<ReferenceField
				label='Categoría'
				source='category'
				reference='cms_categories'
			>
				<ChipField source='name' />
			</ReferenceField>

			<EditButton />
		</Datagrid>
	</List>
);

export default CmsList;
