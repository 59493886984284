import * as React from 'react';
import { AnchorHTMLAttributes, memo, FC } from 'react';
import get from 'lodash/get';
import { Typography, Link } from '@material-ui/core';
import { useRecordContext } from 'ra-core';
import { PublicFieldProps, InjectedFieldProps } from 'react-admin';

const CustomUrlField: FC<CustomUrlFieldProps> = memo((props) => {
	const { className, emptyText, source, prefix } = props;
	const record = useRecordContext(props);
	const value = get(record, source);

	if (value == null) {
		return (
			<Typography component='span' variant='body2' className={className}>
				{emptyText}
			</Typography>
		);
	}

	console.log(value.substring(0, 50));

	return (
		<Link
			className={className}
			href={prefix ? prefix + value : value}
			variant='body2'
			target='_blank'
		>
			{value.length > 50 ? value.substring(0, 50) + '...' : value}
		</Link>
	);
});

CustomUrlField.defaultProps = {
	addLabel: true,
};

CustomUrlField.displayName = 'UrlField';

export interface CustomUrlFieldProps
	extends PublicFieldProps,
		InjectedFieldProps,
		AnchorHTMLAttributes<HTMLAnchorElement> {}

export default CustomUrlField;
