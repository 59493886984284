import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ChipField,
  SingleFieldList,
  NumberField,
  ArrayField,
  DateField,
  TextInput,
  NumberInput,
  EditButton,
  DateTimeInput,
  SelectInput
} from "react-admin";
import { environment } from '../../../../environments/environment';

const filters = [
  <TextInput source="number" label="Id pedido" />,
  <TextInput source="user.email" label="Usuario" />,
  <TextInput source="marketPlaces.name" label="Marca" />,
  <TextInput source="payments.status" label="Estado del pago" />,
  <TextInput source="payments.method.name" label="Metodo de pago" />,
  <NumberInput source="total" label="Importe" />,
  <SelectInput source="status" label="Estado" choices={[
    { id: environment.orderStatus.REQUESTED, name: 'Solicitado' },
    { id: environment.orderStatus.PROCESSED, name: 'Procesado' },
    { id: environment.orderStatus.CANCELED, name: 'Cancelado' },
    { id: environment.orderStatus.RETURNED, name: 'Devolucion' },
    { id: environment.orderStatus.SUPPLEMENT, name: 'Suplemento' },
    { id: environment.orderStatus.ADMITED, name: 'Admitido' },
    { id: environment.orderStatus.BREAKAGE, name: 'Rotura' },
    { id: environment.orderStatus.PREPARED, name: 'Preparado' },
    { id: environment.orderStatus.DISPATCHED, name: 'Expedido' },
    { id: environment.orderStatus.SHIPPED, name: 'Enviado' },
    { id: environment.orderStatus.DELIVERED, name: 'Entregado' },
  ]} />,
  <TextInput source="shipments.number" label="Nº envio" />,
  <SelectInput source="type" label="Tipo pedido" choices={[
    { id: environment.orderTypes.EXTERNAL, name: 'Externo' },
    { id: environment.orderTypes.INTERNAL, name: 'Interno' },
  ]} />,
  <TextInput source="shipments.method.name" label="Metodo de envio" />,
  <DateTimeInput
    label='Fecha de creacion posterior a'
    source='creationDate[after]'
  />,
  <DateTimeInput
    label='Fecha de creacion anterior a'
    source='creationDate[before]'
  />,
];

const OrderList = (props) => (
  <List {...props} filters={filters}>
    <Datagrid>
      <EditButton label="Editar" />

      <DateField source="creationDate" label="Fecha pedido" showTime locales="es-ES" />

      <TextField source="number" label="Id pedido" />

      <ReferenceField label="Usuario" source="user" reference="users">
        <ChipField source="email" />
      </ReferenceField>

      <TextField source="" label="Nº factura" />

      <ArrayField label="Marca" source="marketPlaces">
        <SingleFieldList>
          <ReferenceField reference="market_places" source="@id">
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>

      <ArrayField label="Estado pago" source="payments">
        <SingleFieldList>
          <ReferenceField reference="payments" source="@id">
            <TextField source="status" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>

      <ArrayField label="Método pago" source="payments">
        <ReferenceField reference="payment_methods" source="payments[0].method">
          <TextField source="name" />
        </ReferenceField>
      </ArrayField>

      <NumberField
        source="total"
        label="Importe"
        locales="es-ES"
        options={{ style: "currency", currency: "EUR" }}
      />

      <TextField source="status" label="Estado" />

      <ReferenceField label="Nº envío" source="shipments" reference="shipments">
        <ChipField source="number" />
      </ReferenceField>

      <TextField source="type" label="Tipo pedido" />

      <ReferenceField
        label="Método envío"
        source="shipments"
        reference="shipments"
      >
        <ReferenceField reference="shipment_methods" source="method">
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>

      <ReferenceField
        label="Tipo envío"
        source="shipments"
        reference="shipments"
      >
        <TextField source="type" />
      </ReferenceField>

      <ReferenceField
        label="Suplemento transporte"
        source="supplement"
        reference="supplements"
      >
        <NumberField source="supplement" />
      </ReferenceField>

      <ReferenceField label="Usuario" source="administrator" reference="users">
        <ChipField source="email" />
      </ReferenceField>

      <ReferenceField
        label="ID pedido marca"
        source="marketOrder"
        reference="market_orders"
      >
        <TextField source="purchaseId" />
      </ReferenceField>

      <ReferenceField
        label="ID operación bancaria compra"
        source="marketOrder"
        reference="market_orders"
      >
        <TextField source="transactionId" />
      </ReferenceField>

      <ReferenceField
        label="ID seguimiento pedido marca"
        source="marketOrder"
        reference="market_orders"
      >
        <TextField source="trackingId" />
      </ReferenceField>

      <ReferenceField
        label="Importe compra"
        source="marketOrder"
        reference="market_orders"
      >
        <NumberField
          source="purchaseAmount"
          locales="es-ES"
          options={{ style: "currency", currency: "EUR" }}
        />
      </ReferenceField>

      <ReferenceField
        label="Nº factura"
        source="marketOrder"
        reference="market_orders"
      >
        <TextField source="invoiceNumber" />
      </ReferenceField>

      <ReferenceField
        label="Factura"
        source="marketOrder"
        reference="market_orders"
      >
        <TextField source="invoice" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default OrderList;
