import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import MediaObjectList from './MediaObjectList/MediaObjectList';
import MediaObjectCreate from './MediaObjectCreate/MediaObjectCreate';
import MediaObjectEdit from './MediaObjectEdit/MediaObjectEdit';

const MediaObjectResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={MediaObjectList}
			create={MediaObjectCreate}
			edit={MediaObjectEdit}
			show={null}
		/>
	);
};

MediaObjectResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default MediaObjectResource;
