import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";

const IslandEdit = (props) => (
  <Edit {...props} title="Islas">
    <SimpleForm redirect="list">
      <TextInput source="name" label="Nombre" />
      <ArrayInput source="cpPrefixes" label="Prefijos">
        <SimpleFormIterator>
          <TextInput label="Prefijo" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="province" label="Provincia" />

      <NumberInput source="planeExtraCost" label="Coste extra avión" min={0} />
      <NumberInput source="boatExtraCost" label="Coste extra barco" min={0} />
    </SimpleForm>
  </Edit>
);

export default IslandEdit;
