import React from "react";
import PropTypes from "prop-types";
import { Resource } from "react-admin";
import SupplementList from "./SupplementList/SupplementList";

const SupplementResource = ({ ...props }) => {
  return (
    <Resource
      {...props}
      list={SupplementList}
      create={null}
      edit={null}
      show={null}
    />
  );
};

SupplementResource.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SupplementResource;
