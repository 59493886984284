import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import MarketplaceCategoryList from './MarketplaceCategoryList/MarketplaceCategoryList';
import MarketPlaceCategoryCreate from './MarketplaceCategoryCreate/MarketplaceCategoryCreate';
import MarketPlaceCategoryEdit from './MarketplaceCategoryEdit/MarketplaceCategoryEdit';

const MarketplaceCategoryResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={MarketplaceCategoryList}
			create={MarketPlaceCategoryCreate}
			edit={MarketPlaceCategoryEdit}
			show={null}
		/>
	);
};

MarketplaceCategoryResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default MarketplaceCategoryResource;
