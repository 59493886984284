import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import ItemList from './ItemList/ItemList';

const ItemResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={ItemList}
			create={null}
			edit={null}
			show={null}
		/>
	);
};

ItemResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default ItemResource;
