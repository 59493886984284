import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import CmsCategoryList from './CmsCategoryList/CmsCategoryList';
import CmsCategoryCreate from './CmsCategoryCreate/CmsCategoryCreate';
import CmsCategoryEdit from './CmsCategoryEdit/CmsCategoryEdit';

const CmsCategoryResource = ({ ...props }) => {
	return (
		<Resource
			{...props}
			list={CmsCategoryList}
			create={CmsCategoryCreate}
			edit={CmsCategoryEdit}
			show={null}
		/>
	);
};

CmsCategoryResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default CmsCategoryResource;
