import {
	Create,
	SimpleForm,
	TextInput,
	NumberInput,
	ImageField,
	ImageInput,
	required,
} from 'react-admin';

const MediaObjectCreate = (props) => (
	<Create {...props} title='Imagenes'>
		<SimpleForm redirect='list'>
			<TextInput source='name' label='Nombre' validate={required()} />
			<TextInput
				source='description'
				label='Descripción'
				validate={required()}
			/>
			<TextInput source='title' label='Título' validate={required()} />
			<NumberInput source='size' label='Tamaño' validate={required()} />
			<ImageInput source='file' label='Imagen' validate={required()}>
				<ImageField source='src' />
			</ImageInput>
		</SimpleForm>
	</Create>
);

export default MediaObjectCreate;
