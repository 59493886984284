import {
	Create,
	SimpleForm,
	TextInput,
	ReferenceInput,
	SelectInput,
	required,
} from 'react-admin';

const AddressCreate = (props) => (
	<Create {...props} title='Direcciones'>
		<SimpleForm redirect='list'>
			<TextInput source='street' label='Calle' validate={required()} />
			<TextInput source='number' label='Número' validate={required()} />
			<TextInput source='additionalInfo' label='Mas info' validate={required()} />
			<TextInput source='city' label='Ciudad' validate={required()} />
			<TextInput
				source='postalCode'
				label='Código postal'
				validate={required()}
			/>

			<ReferenceInput
				label='Isla'
				source='island'
				reference='islands'
				validate={required()}
			>
				<SelectInput optionText='name' />
			</ReferenceInput>

			<TextInput source='name' label='Nombre' validate={required()} />
			<TextInput source='surnames' label='Apellidos' validate={required()} />
			<TextInput source='email' label='Email' validate={required()} />
			<TextInput source='phoneNumber' label='Teléfono' validate={required()} />
			<TextInput source='nie' label='NIE' validate={required()} />

			<ReferenceInput
				label='Usuario'
				source='user'
				reference='users'
				validate={required()}
			>
				<SelectInput optionText='email' />
			</ReferenceInput>
		</SimpleForm>
	</Create>
);

export default AddressCreate;
