import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';
import VariableList from './VariableList/VariableList';
import VariableCreate from './VariableCreate/VariableCreate';
import VariableEdit from './VariableEdit/VariableEdit';

const VariableResource = ({ ...props }) => {
    return (
        <Resource
            {...props}
            list={VariableList}
            create={VariableCreate}
            edit={VariableEdit}
            show={null}
        />
    );
};

VariableResource.propTypes = {
    name: PropTypes.string.isRequired,
};

export default VariableResource;
