import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';

const ShipmentMethodEdit = (props) => (
	<Edit {...props} title='Metodos de envio'>
		<SimpleForm redirect='list'>
			<TextInput source='name' label='Nombre' />
			<NumberInput source='price' label='Precio' min={0}/>
		</SimpleForm>
	</Edit>
);

export default ShipmentMethodEdit;
