import React from "react";
import PropTypes from "prop-types";
import { Resource } from "react-admin";
import MarketOrderList from "./MarketOrderList/MarketOrderList";

const MarketOrderResource = ({ ...props }) => {
  return (
    <Resource
      {...props}
      list={MarketOrderList}
      create={null}
      edit={null}
      show={null}
    />
  );
};

MarketOrderResource.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MarketOrderResource;
