import {
	Create,
	SimpleForm,
	TextInput,
	NumberInput,
	required,
} from 'react-admin';

const ShipmentMethodCreate = (props) => (
	<Create {...props} title='Metodos de envio'>
		<SimpleForm redirect='list'>
			<TextInput source='name' label='Nombre' validate={required()} />
			<NumberInput 
				source='price' 
				label='Precio' 
				min={0}
				validate={required()} 
			/>
		</SimpleForm>
	</Create>
);

export default ShipmentMethodCreate;
