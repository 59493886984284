import {
    List,
    Datagrid,
    TextField,
    TextInput,
    EditButton,
} from 'react-admin';

const filters = [
    <TextInput source='name' label='Nombre' />,
    <TextInput source='value' label='Valor' />,
    <TextInput source='description' label='Descripcion' />,
];

const VariableList = (props) => (
    <List {...props} filters={filters}>
        <Datagrid>
            <TextField source='name' label='Nombre' />
            <TextField source='value' label='Valor' />
            <TextField source='description' label='Descripcion' />
            <EditButton label='Editar' />
        </Datagrid>
    </List>
);

export default VariableList;
