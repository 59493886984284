import {
	Create,
	TextInput,
	PasswordInput,
	required,
	SaveButton,
	FormWithRedirect,
	useDataProvider,
	useRedirect,
	useNotify,
} from 'react-admin';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const UserCreate = (props) => {
	const dataProvider = useDataProvider();
	const redirect = useRedirect();
	const notify = useNotify();

	const handleSubmit = async (values) => {
		console.log(dataProvider);
		dataProvider
			.register(values)
			.then(() => {
				redirect(`/users`);
			})
			.catch((error) => {
				notify(error.message, 'error');
			});
	};

	return (
		<Create {...props} title='Usuarios'>
			<FormWithRedirect
				resource='register'
				save={handleSubmit}
				render={({ handleSubmitWithRedirect, pristine, saving }) => (
					<>
						<DialogContent>
							<TextInput source='name' label='Nombre' validate={required()} />
							<br />
							<TextInput source='email' label='Email' validate={required()} />
							<br />
							<PasswordInput
								source='plainPassword'
								label='Contraseña'
								validate={required()}
							/>
						</DialogContent>

						<DialogActions>
							<SaveButton
								label='Guardar'
								handleSubmitWithRedirect={handleSubmitWithRedirect}
								pristine={pristine}
								saving={saving}
								re
							/>
						</DialogActions>
					</>
				)}
			/>
		</Create>
	);
};

export default UserCreate;
