import {
  ReferenceInput,
  TextInput,
  SelectInput,
  Create,
  SimpleForm,
  required,
} from "react-admin";
import JoditEditor from "jodit-react";

const CmsCreate = (props) => {
  let cmsData = "";
  const transform = (data) => ({
    ...data,
    content: cmsData,
  });

  return (
    <Create {...props} transform={transform} title="CMS">
      <SimpleForm redirect="list">
        <TextInput source="name" label="Nombre" validate={required()} />

        <JoditEditor
          data=""
          onChange={(newContent) => {
            cmsData = newContent;
          }}
        />

        <ReferenceInput
          label="Categoría"
          source="category"
          reference="cms_categories"
          validate={required()}
        >
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default CmsCreate;
