import {
	List,
	Datagrid,
	TextField,
	DateField,
	ReferenceField,
	ChipField,
	TextInput,
	NumberInput,
	DateInput,
	SelectArrayInput,
} from 'react-admin';

const filters = [
	<SelectArrayInput
		label='Estado'
		source='status'
		choices={[
			{ id: 'pending', name: 'Pendiente' },
			{ id: 'confirmed', name: 'Confirmado' },
			{ id: 'error', name: 'Error' },
		]}
	/>,
	<DateInput
		source='estimatedArrivalDate[before]'
		label='Fecha de llegada prevista anterior a'
	/>,
	<DateInput
		source='estimatedArrivalDate[after]'
		label='Fecha de llegada prevista posterior a'
	/>,
	<NumberInput source='order.number' label='Pedido' />,
	<TextInput source='user.email' label='Usuario' />,
	<TextInput source='method' label='Método' />,
];

const ShipmentList = (props) => (
	<List {...props} filters={filters}>
		<Datagrid>
			<TextField source='status' label='Estado' />
			<DateField
				source='estimatedArrivalDate'
				label='Fecha de llegada prevista'
			/>

			<ReferenceField label='Pedido' source='order' reference='orders'>
				<ChipField source='number' />
			</ReferenceField>

			<ReferenceField label='Usuaio' source='user' reference='users'>
				<ChipField source='email' />
			</ReferenceField>

			<ReferenceField
				label='Método'
				source='method'
				reference='shipment_methods'
			>
				<ChipField source='name' />
			</ReferenceField>
		</Datagrid>
	</List>
);

export default ShipmentList;
