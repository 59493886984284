import {
	Create,
	SimpleForm,
	TextInput,
	ReferenceInput,
	SelectInput,
	required,
} from 'react-admin';

const MarketPlaceCategoryCreate = (props) => {
	const transform = (data) => ({
		...data,
		childrens: [],
	});

	return (
		<Create {...props} transform={transform} title='Categorias'>
			<SimpleForm redirect='list'>
				<TextInput source='name' label='Nombre' validate={required()} />
				<TextInput
					source='description'
					label='Descripción'
					validate={required()}
				/>

				<ReferenceInput
					source='parent'
					reference='market_place_categories'
					label='Padre'
					allowEmpty
				>
					<SelectInput source='name' />
				</ReferenceInput>
			</SimpleForm>
		</Create>
	);
};

export default MarketPlaceCategoryCreate;
