import React from 'react';
import PropTypes from 'prop-types';
import { Resource } from 'react-admin';

const OrderHistoryResource = ({ ...props }) => {
	return <Resource {...props} />;
};

OrderHistoryResource.propTypes = {
	name: PropTypes.string.isRequired,
};

export default OrderHistoryResource;
